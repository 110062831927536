.tile {
    width: 4%;
    height: var(--hex-tile-size);
    margin: -.5%;
    display: inline-block;
    font-family: 'Roboto Mono', monospace;
    user-select: none;
    pointer-events: all;
}

.selected {
    z-index: 10000;
    cursor: pointer;
}