.grid {
    --hex-tile-size: 3%;
}

.row:first-child {
    margin-top: 0vw;
}

.row div:nth-child(odd) {
    vertical-align: top;
}

.row div:nth-child(even) {
    margin-top: 1.5%;
}

.row {
    margin: 0;
    margin-top: -2.2%;
    pointer-events: none;
}