.content {
    background-color: black;
}

.logo {
    height: 30vh;
    margin-top: 5vh;
}

/* .inputBox {
    max-width: 30em;
} */

/* .input {
    margin: 1em;
} */